<template>
  <div class="animated fadeIn">
    <DataViewWrapper
      ref="dataView"
      :filters="filters"
      :table-columns="tableColumns"
      :model="model"
    />
  </div>
</template>

<script>
import models from '@/models'

export default {
  props: {
    filter: {
      type: String,
      default: ''
    }
  },
  name: 'AvailableProducts',
  components: {},
  data: function () {
    return {
      model: models.warehouse.availableProducts,
      filters: [
        'Finished Product',
        'Warehouse',
        'Warehouse Status',
        'Organic Status',
        'Bottle Size',
        'Date Bottled',
        'Shipped Before',
        'Shipped After'
      ],
      tableColumns: [
        'ID',
        'Product',
        'Batch Number',
        'Organic Status',
        'Bottles Remaining',
        'Bottles Calc',
        'Total Bottles',
        'Used Bottles',
        'Loss Bottles',
        'Cases',
        'Bottling #',
        'Date Bottled',
        'Min Invoice Date',
        'Max Invoice Date',
        'Bottle Size',
        'Error',
        'Err Calc',
        'Warehouse'
      ]
    }
  },
  computed: {},
  created () {},
  mounted () {
    //let filters = this.$refs.dataView.getFilters();

    if (this.filter) {
      // console.log('filter', this.filter)

      this.$refs.dataView.setFilters(JSON.parse(this.filter))
      this.$refs.dataView.getData()
    }
  },
  methods: {},
  watch: {}
}
</script>

<style></style>
